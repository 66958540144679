<template>
    <div>
        <Modal
                v-model="deleteModal"
                title="删除项目"
                width="400"
                :footer-hide="footHide"
        >
            <div>
                <div style="padding: 20px">
                    <div
                            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
                    >
                        <div style="margin-bottom: 30px">
                            <span>您确定删除该项目吗？</span>
                        </div>
                        <div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                                    @click="delCancel()"
                            >
                                我再想想
                            </div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                                    @click="confirmDel()"
                            >
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <div class="right_header">
            <Row :gutter="16">
                <Col span="5">
                    <span>项目名称</span>
                    <Input v-model="params.projectName"/>
                </Col>
                <Col span="5">
                    <span>项目状态</span>
                    <Select v-model="params.status" :transfer="transfer">
                        <Option value="">全部</Option>
                        <Option value="1">报名中</Option>
                        <Option value="2">专家评审中</Option>
                        <Option value="3">委员会评审中</Option>
                        <Option value="4">申报完成</Option>
                    </Select>
                </Col>
                <Col span="5">
                    <div
                            @click="search()"
                            class="button_style"
                            style="
            height: 41px;
            cursor: pointer;
              position: relative;
              top: 50%;
            "
                    >
                        查询
                    </div>
                </Col>
            </Row>
        </div>
        <div class="right_real_content">
            <Table border :columns="columns12" :data="data7" :disabled-hover="true">
                <template slot-scope="{ row }" slot="name">
                    <strong>{{ row.name }}</strong>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <div v-if="row.statusName === '暂存'">
                        <Button
                                type="primary"
                                size="small"
                                style="margin-right: 5px; color: rgb(55, 197, 207)"
                                @click="continueSb()"
                        >继续申报
                        </Button
                        >
                        <span class="operation_span">|</span>
                        <Button
                                type="error"
                                size="small"
                                style="color: rgb(55, 197, 207)"
                                @click="delObj(row)"
                        >删除
                        </Button
                        >
                    </div>
                    <div
                            v-if="
              row.statusName === '委员会评审中' || row.statusName === '专家评审中' || row.statusName === '报名中'
            "
                    >
                        <Button
                                type="primary"
                                size="small"
                                style="margin-right: 5px; color: rgb(55, 197, 207)"
                                @click="downloadContent(row)"
                        >下载申报内容
                        </Button
                        >
                    </div>
                    <div v-else>
                        <Button
                                type="primary"
                                size="small"
                                style="margin-right: 5px; color: rgb(55, 197, 207)"
                                @click="downloadContent(row)"
                        >下载申报内容
                        </Button
                        >
                        <span class="operation_span">|</span>
                        <Button
                                type="error"
                                size="small"
                                style="color: rgb(55, 197, 207)"
                                @click="downloadResult(row)"
                        >下载申报结果
                        </Button
                        >
                    </div>
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
    import {getQyxmk, delQyxmk} from "../../../plugins/api/sciencePolicy";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";
    import axios from "axios";
    import {patentUrl, policyUrl} from "../../../plugins/http/baseUrl";
    import {getLocalToken} from "../../../plugins/tools/storage";

    export default {
        name: "EnterpriseProjectLibrary",
        mounted() {
            this.getXm();

        },
        data() {
            return {
                footHide: true,
                deleteId: "",
                deleteModal: false,
                params: {
                    projectName: "",
                    status: "",
                    pageNum: 1,
                    pageSize: 10,
                },

                transfer: true,
                columns12: [
                    {
                        title: "项目名称",
                        key: "projectName",
                        align: "center",
                        render: (h, params) => {
                            return h('div', [
                                h('span', {
                                    style: {
                                        display: '-webkit-box',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        '-webkit-line-clamp': '2',
                                        '-webkit-box-orient': 'vertical'
                                    },
                                    domProps: {
                                        title: params.row.projectName
                                    }
                                }, params.row.projectName)
                            ])
                        }
                    },
                    {
                        title: "申报单位",
                        key: "declareEnter",
                        align: "center",
                        render: (h, params) => {
                            return h('div', [
                                h('span', {
                                    style: {
                                        display: '-webkit-box',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        '-webkit-line-clamp': '2',
                                        '-webkit-box-orient': 'vertical'
                                    },
                                    domProps: {
                                        title: params.row.declareEnter
                                    }
                                }, params.row.declareEnter)
                            ])
                        }
                    },
                    {
                        title: "申报期限",
                        key: "qixian",
                        align: "center",
                        render: (h, params) => {
                            return h('div', [
                                h('span', {
                                    style: {
                                        display: '-webkit-box',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        '-webkit-line-clamp': '2',
                                        '-webkit-box-orient': 'vertical'
                                    },
                                    domProps: {
                                        title: params.row.qixian
                                    }
                                }, params.row.qixian)
                            ])
                        }
                    },
                    {
                        title: "申报进度",
                        key: "statusName",
                        align: "center",

                    },
                    {
                        title: "操作",
                        slot: "action",
                        align: "center",
                    },
                ],
                data7: [],
                xmmc: "",
                sbmc: "",
                xmzt: "",
            };
        },
        methods: {
            // 得到列表
            async getXm() {
                let json = await getQyxmk(this.params);
                if (json.code == "200") {
                    this.data7 = json.data;
                    this.data7.forEach((item, index) => {
                        let dateee = new Date(item.startTime).toJSON();
                        item.startTime = new Date(+new Date(dateee) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                        let dateee2 = new Date(item.endTime).toJSON();
                        item.endTime = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                        item.qixian = item.startTime + "一" + item.endTime;
                    });
                } else {
                    errorToast(json.message);
                }
                this.$parent.changeHeight();
            },

            //search
            search() {
                this.getXm();
            },

            async confirmDel() {
                let data = {
                    projectId: this.projectId,
                };
                let json = await delQyxmk(data);
                if (json.code === "200") {
                    successToast("删除成功");
                    this.getXm();
                    this.deleteModal = false;
                } else {
                    errorToast(json.message);
                }
            },
            delCancel() {
                this.deleteModal = false;
            },
            // 下载内容
            async downloadContent(row) {
                let json = await axios({
                    method: "get",
                    url: `${policyUrl}/project/declareExport?projectId=${row.projectId}`,
                    responseType: "arraybuffer",
                    headers: {token: getLocalToken()},
                });
                let fileName = `${row.projectName}-申报内容.pdf`;
                let blob = new Blob([json.data], {
                    type: "application/vnd.ms-excel};charset=utf-8",
                });
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = fileName;
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);

            },
            continueSb() {
                location.reload();
            },
            // 下载结果
            async downloadResult(row) {

                let json = await axios({
                    method: "get",
                    url: `${policyUrl}/project/resultExport?projectId=${row.projectId}`,
                    responseType: "arraybuffer",
                    headers: {token: getLocalToken()},
                });
                let fileName = `${row.projectName}-申报结果.pdf`;
                let blob = new Blob([json.data], {
                    type: "application/vnd.ms-excel};charset=utf-8",
                });
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = fileName;
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
            },
            // 删除
            delObj(item) {
                this.deleteId = item.projectId;
                this.deleteModal = true;
            },
            // 继续申报
            continue() {
            },
            // 下载公示
            downloadGong() {
            },
        },
    };
</script>

<style scoped lang="scss">
    /deep/ .ivu-table-border td,
    .ivu-table-border th {
        border-right: none;
    }

    /deep/ .ivu-table-tbody tr:nth-child(2n) td {
        background-color: #f3fcfc !important;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }
</style>
